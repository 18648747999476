
import { computed, defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { VueEditor } from "vue3-editor";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AxiosRequestConfig } from "axios";
import { useI18n } from "vue-i18n";
import { event } from "vue-gtag";

interface Exercise {
  subject: string;
  title: string;
  module: string;
  desc: string;
  deadline: string;
  marked: boolean;
  resources: File[];
  trimester: string;
  classrooms: string[];
  students?: string[];
  status: string;
}

interface File {
  name: string;
  url: string;
  storedName: string;
}

interface Subject {
  _id: string;
  name: string;
  status: string;
}

interface Module {
  _id: string;
  name: string;
  subjects: Subject[];
  status: string;
}

interface ClassRoom {
  _id: string;
  name: string;
  subjects: Subject[];
}
interface Student {
  _id: string;
  firstName: string;
  lastName: string;
  schoolarYearsHistory: string[];
}

export default defineComponent({
  name: "edit-exercise",
  components: {
    ErrorMessage,
    Field,
    Form,
    VueEditor,
  },
  props: {
    id: String,
  },
  setup() {
    const { t } = useI18n();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const loadingSktelton = ref(true);

    // eslint-disable-next-line
    const upload = ref<any>(null);
    const subjects = ref<Subject[]>([]);
    const modules = ref<Module[]>([]);
    const classRooms = ref<ClassRoom[]>([]);
    const uploadPercentage = ref(0);

    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const fetchClassRoomDetails = async (teacherId) => {
      await ApiService.post("/teacher/classRommDetails", {
        query: {
          teacher: teacherId,
        },
      })
        .then(({ data }) => {
          let subjectList: Subject[] = [];
          const classrooms: string[] = [];
          for (const classroom of data) {
            subjectList.push(...classroom.subjects);

            const classRoomSubjects: Subject[] = classroom.subjects;

            classroom.modulesSpecific.map((m) => {
              if (!modules.value.find((t) => t._id == m._id))
                modules.value.push(m);
              classRoomSubjects.push(
                ...m.subjects.filter(
                  (s) => !classRoomSubjects.find((ss) => ss._id == s._id)
                )
              );
            });

            classroom.modules.map((m) => {
              if (!modules.value.find((t) => t._id == m._id))
                modules.value.push(m);
              classRoomSubjects.push(
                ...m.subjects.filter(
                  (s) => !classRoomSubjects.find((ss) => ss._id == s._id)
                )
              );
            });
            //remove duplicated
            subjectList = subjectList.filter(
              (subject, index) =>
                subjectList.findIndex((s) => s._id == subject._id) === index &&
                !modules.value.find((m) =>
                  m.subjects.find((ss) => ss._id == subject._id)
                ) &&
                subject.status !== "inactive"
            );

            classRooms.value.push({
              _id: classroom.classRoom._id,
              name: classroom.classRoom.name,
              subjects: classRoomSubjects,
            });
            classrooms.push(classroom.classRoom._id);
          }
          subjects.value = subjectList;
          getStudents(classrooms);
        })
        .catch((e) => console.log(e));
    };

    const homeworkID = route.params.id;

    const spec = ref<string>("0");

    const jwtTrimester = JwtService.getTrimester();
    let trimester = "1";
    if (jwtTrimester && jwtTrimester != "0") trimester = jwtTrimester;

    const exercise = ref<Exercise>({
      subject: "",
      title: "",
      module: "",
      desc: "",
      deadline: "",
      marked: true,
      resources: [],
      trimester,
      classrooms: [],
      students: [],
      status: "",
    });

    const studentList = ref<any[]>([]);
    const sy = window.localStorage.getItem("activeSchoolarYear");

    const getStudents = (classrooms: string[]) => {
      const match = {};
      match[`schoolarYearsHistory.${sy}`] = { $in: classrooms };
      ApiService.post("/students/filter", {
        query: { status: "active" },
        aggregation: [
          {
            $match: match,
          },
          {
            $set: {
              classRoom: {
                $convert: {
                  input: "$schoolarYearsHistory." + sy,
                  to: "objectId",
                  onError: null,
                  onNull: null,
                },
              },
            },
          },
          {
            $project: {
              _id: 1,
              firstName: 1,
              lastName: 1,
              schoolarYearsHistory: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          studentList.value = data;
        })
        .catch((e) => console.log(e));
    };

    const validationSchema = Yup.object().shape({
      title: Yup.string().required(t("course.requiredTitle")),
      subject: Yup.string().required(t("course.requiredSubject")),
      students: Yup.array().test(
        "required-students",
        t("course.requiredStudents"),
        (value) => {
          if (spec.value == "0") return true;
          if (spec.value == "1" && (value?.length == 0 || value == undefined))
            return false;
          else return true;
        }
      ),
      classrooms: Yup.array()
        .min(1, t("course.requiredClassroom"))
        .required(t("course.requiredClassroom")),
    });

    const getFileName = (file: string): string => {
      const t = file.indexOf("--");
      const fileName = file.substring(t == -1 ? 0 : t + 2);
      return fileName;
    };

    const getFileUrl = (file: string): string => {
      return (
        store.getters.serverConfigUrl.base_url + "/" + file.replace(/\\/g, "//")
      );
    };

    onMounted(async () => {
      await ApiService.get(`/lms/homework/${homeworkID}`)
        .then(async ({ data }) => {
          await fetchClassRoomDetails(data.teacher._id);
          exercise.value = {
            subject: data.subject._id,
            title: data.title,
            module: data.module,
            desc: data.desc,
            marked: data.marked,
            deadline: data.deadline,
            resources: [],
            trimester: data.trimester ? data.trimester : "0",
            classrooms: data.classrooms,
            students: data.students,
            status: data.status,
          };

          data.resources.forEach((file) => {
            exercise.value.resources.push({
              storedName: file,
              name: getFileName(file),
              url: getFileUrl(file),
            });
          });

          if (data.students && data.students.length != 0) {
            spec.value = "1";
          }

          if (data.status == "validated") router.push("/404");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          loadingSktelton.value = false;
        });
    });

    // eslint-disable-next-line
    const uploadFiles = ref<any>([]);

    const updateUploadFiles = (file, files) => {
      uploadFiles.value = files;
    };

    const submit = () => {
      event("Edit exercise", {
        event_category: "Exercise",
        event_label: "Exercise section",
        value: 1,
      });

      const data = new FormData();

      if (!exercise.value.deadline) exercise.value.deadline = "";

      if (exercise.value.students && exercise.value.students?.length != 0) {
        exercise.value.students?.forEach((st: string) => {
          data.append("students", st);
        });
      }

      for (const name in exercise.value)
        if (!["classrooms", "status", "students", "resources"].includes(name))
          data.append(name, exercise.value[name] ? exercise.value[name] : "");

      if (exercise.value.classrooms.length != 0) {
        exercise.value.classrooms.forEach((cr: string) => {
          data.append("classrooms", cr);
        });
      }

      if (uploadFiles.value.length > 0) {
        uploadFiles.value.forEach((file) => {
          if (file.raw) data.append("resources", file.raw, file.raw.name);
          else data.append("resources[]", file.storedName);
        });
      } else {
        exercise.value.resources.map((file) => {
          data.append("resources[]", file.storedName);
        });
      }
      console.log(exercise.value);
      if (!submitButtonRef.value) {
        return;
      }

      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

      ApiService.patch(
        `/lms/homework/${homeworkID}`,
        data as AxiosRequestConfig,
        {
          onUploadProgress: function (progressEvent) {
            uploadPercentage.value = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        } as any
      )
        .then(() => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }

          Swal.fire({
            text: t("course.editedExerciseInfo"),
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: t("course.okay"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          }).then(() => {
            if (store.getters.hasAdvisorAccess)
              router.push(`/exercisesValidation/${homeworkID}`);
            else router.push(`/exercise/${homeworkID}`);
          });
        })
        .catch((err) => {
          if (err.response.status == 413)
            Swal.fire({
              text: t("course.filesTooLarge"),
              icon: "warning",
              buttonsStyling: false,
              confirmButtonText: t("course.okay"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          else
            Swal.fire({
              text: t("course.errorText"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("course.tryAgain"),
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;

            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
        });
    };

    const clearStudents = () => {
      exercise.value.students = [];
    };

    const avaliableClassRooms = computed(() =>
      classRooms.value.filter((c: ClassRoom) =>
        c.subjects.find((s: Subject) => s._id == exercise.value.subject)
      )
    );

    const avaliableStudents = computed(() =>
      studentList.value.filter((s: Student) =>
        exercise.value.classrooms.includes(s.schoolarYearsHistory[sy as string])
      )
    );

    const handleExceed = () => {
      Swal.fire({
        text: t("course.maxFilesUploadExceed"),
        icon: "info",
        buttonsStyling: false,
        confirmButtonText: t("course.tryAgain"),
        customClass: {
          confirmButton: "btn fw-bold btn-light-info",
        },
      });
    };

    const customColorMethod = (percentage: number) => {
      if (percentage < 30) {
        return "#909399";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    };

    return {
      t,
      homeworkID,
      subjects,
      modules,
      spec,
      clearStudents,
      avaliableClassRooms,
      avaliableStudents,
      upload,
      exercise,
      validationSchema,
      submit,
      submitButtonRef,
      studentList,
      updateUploadFiles,
      handleExceed,
      uploadPercentage,
      customColorMethod,
      store,
      loadingSktelton,
    };
  },
});
